import { Box, Text } from "@chakra-ui/react";
import { Testimonial } from "./Testimonials";

export default function TestimonialsPage() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box m={8} mt={32} width={{ base: "100%", md: "80%" }} maxW={"6xl"}>
        <Text fontSize="3xl" mb={12} textAlign="center">What our users are saying</Text>
        <Box display="flex" flexWrap="wrap" justifyContent="space-between" gap={6}>
          <Testimonial name="Chuck" company="Director of Engineering" href="">
          The code suggestions I was getting from Sweep were consistently <b>better than the ones that I was getting from Copilot</b>. Not only were the results better, but I enjoyed working with the Sweep plugin more than Copilot. I also found that Copilot was running a language server taking up <b>5 gigs ram</b> on my machine and slowing down Android Studio, while Sweep didn't have this issue.
          </Testimonial>
          <Testimonial name="Remy" company="Developer" href="">
          I've been using Sweep intensively past two, three weeks. It was really helpful. I think I've achieved like <b>three weeks of work in two days</b>, which is pretty cool.
          </Testimonial>
          <Testimonial name="Anonymous" company="Developer at top hedge fund" href="">
          Indispensable in terms of <b>increasing my productivity</b>.
          </Testimonial>
          <Testimonial name="Alex" company="Startup Founder" href="">
            So, I totally <b>love it</b>.
          </Testimonial>
        </Box>
      </Box>
    </Box>
  );
}