import { Box, Flex, Text } from "@chakra-ui/react";
// import User from "./User";

type TestimonialProps = {
    children: React.ReactNode,
    name: string,
    company: string,
    href: string
}

export const Testimonial = ({ children, name, company, href }: TestimonialProps): JSX.Element => {
    return (
        <Box
            overflow="hidden"
            p={6}
            mb={8}  // Reduced from mb={16}
            width={{ base: "100%", md: "48%" }}
            backgroundColor="purple.900"
            borderRadius="16px"
            borderColor="purple.300"
            borderWidth={1}
            boxShadow="0 4px 12px rgba(0, 0, 0, 0.2)"
            transition="transform 0.3s, box-shadow 0.3s"
            _hover={{
                transform: "translateY(-5px)",
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)"
            }}
        >
            <Flex width="100%" mb={4}>
                <Box flexGrow={1} textAlign="left" pl={0}>
                    <Text fontWeight="bold">{name}</Text>
                    <Text color="#aaa">{company}</Text>
                </Box>
            </Flex>
            <Text fontSize="md" textAlign="left" lineHeight="1.6">
                {children}
            </Text>
        </Box>
    );
}
